import React from "react";
import { Link } from "gatsby";

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Layout from "../components/Layout";

import { useTheme, Subtheme } from '@starberryComponentsMui';


const Testy = (props) => (
    <>
        <Typography variant="h1" component="h1" {...props}>Theme {useTheme().getProp('name')}</Typography>
    </>
);

/*
            {
                ({className, ...props}) => (
                    <Grid item className={className}>
                        <Typography variant="h1">Hi from the second page</Typography>
                        <Paper>
                            <Testy />
                        </Paper>
                        <p>Welcome to page 2</p>
                        <Link to="/">Go back to the homepage</Link>
                    </Grid>
                )
            }
*/

const SecondPage = () => (
    <Layout>
        <Subtheme subtheme="../page2" applyStyling={true} applyProps={true}>{
            ({className, ...props}) => (
                <Grid item className={className}>
                    <Typography variant="h1">Hi from the second page</Typography>
                    <Paper>
                        <Testy />
                    </Paper>
                    <p>Welcome to page 2</p>
                    <Link to="/">Go back to the homepage</Link>
                </Grid>
            )
        }</Subtheme>
    </Layout>
);

export default React.memo(SecondPage);
